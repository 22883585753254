import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import GlobalStyle from './styles/GlobalStyle';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import CV from './pages/Cv';
import styled from 'styled-components';
import ScrollToTopButton from './components/ScrollToTopButton';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.main`
  flex: 1;
`;

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <>
      <GlobalStyle />
      <Router>
        <ScrollToTop />
        <AppContainer>
          <Header />
          <Content>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/cv" element={<CV />} />
            </Routes>
          </Content>
          <Footer />
        </AppContainer>
        <ScrollToTopButton />
      </Router>
    </>
  );
};

export default App;
