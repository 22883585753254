import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Modal from './Modal';
import { useTranslation } from 'react-i18next';

const Card = styled(motion.div)`
  background-color: var(--bg-color);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    outline: 3px solid #333;
    outline-offset: 4px;
  }

  img {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }

  h3 {
    font-size: 1.5rem;
    margin: 20px;
  }

  p {
    font-size: 1rem;
    margin: 0 20px 10px;
    color: var(--project-text-color);
  }
`;

const TechnologiesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  li {
    background-color: #f1f1f1;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8rem;
    color: #555;
    flex: 1 1 auto;
    text-align: center;
  }
`;

const ModalHeader = styled.div`
  margin-bottom: 20px;

  h2 {
    margin-bottom: 10px;
    font-size: 2rem;
    color: #333;
  }

  img {
    width: 50%;
    height: 50%;
    border-radius: 10px;
  }
`;

const ModalBody = styled.div`
  p {
    font-size: 1rem;
    color: black;
    margin-bottom: 20px;
    line-height: 1.6;
  }

  h4 {
    color: var(--primary-color);
  }
`;

const ProjectCard = ({ project }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <Card
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        onClick={handleOpenModal}
        tabIndex={0}
        role="button"
        aria-pressed="false"
        aria-label={t('viewDetails', { title: t(project.titleKey) })}
      >
        <img src={project.image} alt={t('projectImageAlt', { title: t(project.titleKey) })} loading="lazy" />
        <h3>{t(project.titleKey)}</h3>
        <p>{t(project.shortDescriptionKey)}</p>
        <TechnologiesList>
          {project.technologies.map((tech, index) => (
            <li key={index}>{tech}</li>
          ))}
        </TechnologiesList>
      </Card>

      <Modal show={showModal} onClose={handleCloseModal}>
        <ModalHeader>
          <h2 id="modalTitle">{t(project.titleKey)}</h2>
          <img src={project.image} alt={t('projectFullImageAlt', { title: t(project.titleKey) })} loading="lazy" />
        </ModalHeader>
        <ModalBody>
          <p>{t(project.descriptionKey)}</p>
          <h4>{t('usedTechnologies')}</h4>
          <TechnologiesList>
            {project.technologies.map((tech, index) => (
              <li key={index}>{tech}</li>
            ))}
          </TechnologiesList>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ProjectCard;
