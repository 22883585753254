import React, { useState } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import Notification from '../components/Notification'; 
import DOMPurify from 'dompurify';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

const AboutContainer = styled.div`
  padding: 60px 20px;
  background-color: var(--bg-color);
  color: var(--text-color);
  text-align: center;
  margin-top: 80px;
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  background-color: var(--bg-color);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 20px;
`;

const Section = styled.div`
  margin-bottom: 30px;

  h3 {
    font-size: 1.5rem;
    color: var(--about-color);
    margin-bottom: 10px;
  }

  p, a {
    font-size: 1rem;
    color: var(--about-color);
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Label = styled.label`
  font-size: 1rem;
  color: var(--text-color);
  text-align: left;
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--secondary-color);
  background-color: var(--bg-color);
  color: var(--about-color);
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;

  &::placeholder {
    color: var(--secondary-color);
  }
`;

const Textarea = styled.textarea`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--secondary-color);
  background-color: var(--bg-color);
  color: var(--about-color);
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  resize: vertical;

  &::placeholder {
    color: var(--secondary-color);
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--bg-color);
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--secondary-color);
  }

  &:focus {
    outline: 3px solid var(--secondary-color);
    outline-offset: 4px;
  }
`;

const About = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [notification, setNotification] = useState({ message: '', success: true });
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const containsLink = (message) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return urlPattern.test(message);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate CAPTCHA
    if (!captchaToken) {
      setNotification({ message: t('captchaVerification'), success: false });
      return;
    }

    // Check for links in the message
    if (containsLink(formData.message)) {
      setNotification({ message: t('messagesWithLinksNotAllowed'), success: false });
      return;
    }

    // Sanitize input
    const sanitizedData = {
      name: DOMPurify.sanitize(formData.name),
      email: DOMPurify.sanitize(formData.email),
      message: DOMPurify.sanitize(formData.message),
    };

    emailjs.send(
      'service_ob1jpgr',  // service ID
      'template_quhye4c', // template ID
      sanitizedData,
      'Wp960cE6B4dYNJZc5' // user ID
    )
      .then((result) => {
        setNotification({ message: t('messageSent'), success: true });
      }, (error) => {
        setNotification({ message: t('somethingWentWrong'), success: false });
      });

    // Reset form after submission
    setFormData({
      name: '',
      email: '',
      message: '',
    });

    // Show notification for 3 seconds
    setTimeout(() => {
      setNotification({ message: '', success: true });
    }, 3000);

    // Reset the CAPTCHA
    setCaptchaToken(null);
  };

  return (
    <AboutContainer>
      <Content>
        <Title>{t('aboutMe')}</Title>
        <Section>
          <h3>LinkedIn</h3>
          <a href="https://www.linkedin.com/in/dylan-leijten-6b4655104/" target="_blank" rel="noopener noreferrer">
            {t('linkedinProfile')}
          </a>
        </Section>
        <Section>
          <h3>Email</h3>
          <p>
            {t('youCanReachMeViaEmail')}: <a href="mailto:dylan@dyluxe-code.nl">dylan@dyluxe-code.nl</a>
          </p>
        </Section>
        <Section>
          <h3>{t('contactForm')}</h3>
          <Form onSubmit={handleSubmit}>
            <Label htmlFor="name">{t('yourName')}</Label>
            <Input
              id="name"
              name="name"
              type="text"
              placeholder={t('yourName')}
              value={formData.name}
              onChange={handleChange}
              required
            />

            <Label htmlFor="email">{t('yourEmail')}</Label>
            <Input
              id="email"
              name="email"
              type="email"
              placeholder={t('yourEmail')}
              value={formData.email}
              onChange={handleChange}
              required
            />

            <Label htmlFor="message">{t('yourMessage')}</Label>
            <Textarea
              id="message"
              name="message"
              rows="5"
              placeholder={t('yourMessage')}
              value={formData.message}
              onChange={handleChange}
              required
            />

            <ReCAPTCHA
              sitekey="6Le1fTMqAAAAALY_Z5Ew612DsGZIBx18rLMuAXLN"  
              onChange={handleCaptchaChange}
            />

            <SubmitButton type="submit">{t('sendMessage')}</SubmitButton>
          </Form>
        </Section>
      </Content>
      {notification.message && (
        <Notification message={notification.message} success={notification.success} />
      )}
    </AboutContainer>
  );
};

export default About;
