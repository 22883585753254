// src/pages/Home.js
import React from 'react';
import Hero from '../components/Hero';
import ProjectsGrid from '../components/ProjectsGrid';

const Home = () => {
  return (
    <div>
      <Hero />
      <ProjectsGrid />
    </div>
  );
};

export default Home;
