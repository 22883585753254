import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: var(--bg-color);
  color: var(--text-color);
  text-align: center;
  padding: 20px;
  position: relative;
  width: 100%;
  bottom: 0;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);
    p {
    color: var(--footer-text-color); 
  }

  div {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 20px;

    a {
      color: var(--footer-text-color);
      font-size: 1.2rem;
      transition: color 0.3s ease;

      &:hover {
        color: var(--primary-color);
      }

      &:focus {
        outline: 2px solid var(--secondary-color);
        outline-offset: 4px;
      }
    }
  }
`;

const Footer = () => (
  <FooterContainer>
    <p>&copy; 2024 Dylan Leijten.  All rights reserved.</p>
    <div>
      <a href="mailto:dylan@dyluxe-code.nl" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
        Mail
      </a>
      <a href="https://www.linkedin.com/in/dylan-leijten-6b4655104/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
        LinkedIn
      </a>
    </div>
  </FooterContainer>
);

export default Footer;
