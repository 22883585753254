import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const NotificationContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: ${(props) => (props.success ? 'var(--primary-color)' : 'var(--secondary-color)')};
  color: white;
  padding: 15px 25px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  z-index: 1000;
  animation: ${fadeIn} 0.5s ease-out;

  @media (max-width: 768px) {
    right: 10px;
    left: 10px;
    bottom: 10px;
  }
`;

const Notification = ({ message, success }) => {
  return (
    <NotificationContainer success={success}>
      {message}
    </NotificationContainer>
  );
};

export default Notification;
