import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Nav = styled.nav`
  padding: 20px;
  background-color: rgba(0, 0, 0, 1);
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;

  &[data-theme='light'] {
    background-color: #ffffff;
    color: #333333;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 10px;
  }

  font-size: 1.5rem;
  font-weight: bold;
`;

const MenuToggle = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: 1.5rem;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const NavLinks = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0;

  @media (max-width: 768px) {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.9);
    display: ${props => (props.isOpen ? 'block' : 'none')};
    padding: 20px;
    text-align: center;
    margin: 0;
    transition: transform 0.3s ease-out;

    &[data-theme='light'] {
      background-color: #ffffff;
      color: #333333;
    }

    li {
      margin-bottom: 10px;
      width: auto;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    position: relative;
    font-weight: 500;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: -3px;
      left: 0;
      background-color: var(--primary-color);
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }

    &:focus-visible {
      outline: 2px solid var(--secondary-color);
      outline-offset: 4px;
    }
  }
`;

const ToggleButton = styled.button`
  background: none;
  border: 1px solid var(--primary-color);
  border-radius: 15px;
  color: var(--primary-color);
  padding: 4px 8px;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
  align-self: center;

  img {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: var(--primary-color);
    color: var(--bg-color);
  }

  &:focus-visible {
    outline: 2px solid var(--secondary-color);
    outline-offset: 4px;
  }
    
  @media (max-width: 768px) {
    justify-content: center; 
    margin: 0 auto; 
  }
`;

const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
`;

const ModalImageWrapper = styled.div`
  position: relative;
`;

const ModalImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
  object-fit: cover;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px 8px;
  font-size: 16px;
  color: #333;
  z-index: 10;

  &:hover {
    background: rgba(255, 255, 255, 1);
  }
`;

const Header = () => {
  const [theme, setTheme] = useState('dark');
  const [menuOpen, setMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navRef = useRef(null);
  const { i18n } = useTranslation(); // Access i18n instance
  const language = i18n.language;

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'));
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  const handleImageClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <>
      <Nav data-theme={theme} aria-label="Main Navigation" ref={navRef}>
        <Logo>
          <img src="/assets/images/dolan2.jpg" alt="Dylan Leijten" loading="lazy" onClick={handleImageClick} />
          <Link to="/" aria-label="Go to homepage" onClick={closeMenu}>Portfolio</Link>
        </Logo>
        <MenuToggle onClick={toggleMenu} aria-label="Menu">
          ☰
        </MenuToggle>
        <NavLinks isOpen={menuOpen} data-theme={theme}>
          <li>
            <Link to="/" aria-label="Go to home" onClick={closeMenu}>Home</Link>
          </li>
          <li>
            <Link to="/cv" aria-label="My resumé" onClick={closeMenu}>CV</Link>
          </li>
          <li>
            <Link to="/about" aria-label="Go to about page" onClick={closeMenu}>About</Link>
          </li>
          <li>
            <ToggleButton onClick={toggleTheme} aria-label="Toggle between dark and light theme">
              {theme === 'dark' ? 'Light Mode' : 'Dark Mode'}
            </ToggleButton>
          </li>
          <li>
            <ToggleButton onClick={() => changeLanguage(language === 'en' ? 'nl' : 'en')} aria-label="Toggle language">
              <img 
                src={language === 'en' ? '/assets/images/nl-flag.png' : '/assets/images/uk-flag.png'} 
                alt={language === 'en' ? 'Nederlands' : 'English'} 
              />
              {language === 'en' ? 'Naar Nederlands' : 'To English'}
            </ToggleButton>
          </li>
        </NavLinks>
      </Nav>

      {showModal && (
        <ModalContainer onClick={handleCloseModal}>
          <ModalImageWrapper>
            <ModalImage src="/assets/images/dolan2.jpg" alt="Enlarged image of Dylan Leijten" />
            <CloseButton onClick={handleCloseModal} aria-label="Close the image">&times;</CloseButton>
          </ModalImageWrapper>
        </ModalContainer>
      )}
    </>
  );
};

export default Header;
