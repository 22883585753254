import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ScrollButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  z-index: 1000;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: ${(props) => (props.visible ? 'translateY(0)' : 'translateY(100px)')};

  &:hover {
    background-color: var(--secondary-color);
  }

  /* Verhoog de positie op tablets */
  @media (max-width: 1024px) and (min-width: 768px) {
    bottom: 85px;  
    right: 20px;
  }

  /* Styling voor kleinere mobiele apparaten */
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 40px;
    bottom: 10px;
    right: 10px;
  }
`;

const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <ScrollButton onClick={scrollToTop} visible={visible} aria-label="Scroll naar boven">
      ↑
    </ScrollButton>
  );
};

export default ScrollToTopButton;
