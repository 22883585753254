import React from 'react';
import styled from 'styled-components';
import ProjectCard from './ProjectCard';
import { useSelector } from 'react-redux';
import { selectProjects } from '../redux/slices/projectSlice';

const GridContainer = styled.div`
  padding: 60px 20px;
  background-color: var(--background-color);
  text-align: center;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: var(--primary-color);
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
`;

const ProjectsGrid = () => {
  const projects = useSelector(selectProjects);

  return (
    <GridContainer id="projects" role="region" aria-labelledby="projectsHeading">
      <h2 id="projectsHeading">My projects</h2>
      <Grid>
        {projects && projects.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </Grid>
    </GridContainer>
  );
};

export default ProjectsGrid;
