import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  projectsCards: [
    {
      id: 1,
      titleKey: 'projectsCards.achmea.title',
      shortDescriptionKey: 'projectsCards.achmea.shortDescription',
      descriptionKey: 'projectsCards.achmea.description',
      image: '/assets/images/achmea.jpg',
      technologies: ['HTML', 'SCSS', 'Javascript', 'jQuery', 'Storybook', 'Azure DevOps'],
    },
    {
      id: 2,
      titleKey: 'projectsCards.robeco.title',
      shortDescriptionKey: 'projectsCards.robeco.shortDescription',
      descriptionKey: 'projectsCards.robeco.description',
      image: '/assets/images/robeco.jpg',
      technologies: ['Vue.js', 'Vuetify', 'Javascript', 'SCSS', 'Azure DevOps', 'Thymeleaf'],
    },
    {
      id: 3,
      titleKey: 'projectsCards.robecoFormComponents.title',
      shortDescriptionKey: 'projectsCards.robecoFormComponents.shortDescription',
      descriptionKey: 'projectsCards.robecoFormComponents.description',
      image: '/assets/images/robeco2.jpg',
      technologies: ['Vue.js', 'Vuetify', 'Accessibility', 'JavaScript'],
    },
    {
      id: 4,
      titleKey: 'projectsCards.robecoFundFinder.title',
      shortDescriptionKey: 'projectsCards.robecoFundFinder.shortDescription',
      descriptionKey: 'projectsCards.robecoFundFinder.description',
      image: '/assets/images/robeco4.jpg',
      technologies: ['Vue.js', 'JavaScript', 'SCSS', 'REST API'],
    },
    {
      id: 5,
      titleKey: 'projectsCards.robecoSelectorTool.title',
      shortDescriptionKey: 'projectsCards.robecoSelectorTool.shortDescription',
      descriptionKey: 'projectsCards.robecoSelectorTool.description',
      image: '/assets/images/robeco3.jpg',
      technologies: ['HTML5', 'Less', 'JavaScript (ES6)', 'Webpack', 'GIT', 'Tridion', 'Azure DevOps', 'Scrum'],
    },
    {
      id: 6,
      titleKey: 'projectsCards.copacoWebApp.title',
      shortDescriptionKey: 'projectsCards.copacoWebApp.shortDescription',
      descriptionKey: 'projectsCards.copacoWebApp.description',
      image: '/assets/images/copaco.jpeg',
      technologies: ['Angular', 'Angular Material', 'HTML', 'CSS', 'TypeScript'],
    },
    {
      id: 7,
      titleKey: 'projectsCards.computariaClientSystem.title',
      shortDescriptionKey: 'projectsCards.computariaClientSystem.shortDescription',
      descriptionKey: 'projectsCards.computariaClientSystem.description',
      image: '/assets/images/computaria.jpg',
      technologies: ['Angular', 'HTML', 'CSS', 'TypeScript'],
    }
  ],
};

const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
});

export const selectProjects = (state) => state.projects.projectsCards;
export default projectSlice.reducer;
