import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const CVContainer = styled.div`
  padding: 60px 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 40px;
  max-width: 1200px;
  width: 100%;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 2fr;
    gap: 20px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

const Sidebar = styled(motion.div)`
  background-color: var(--secondary-color);
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: var(--sidebar-text-color);
  text-align: center;

  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
    border: 3px solid var(--primary-color);

    @media (max-width: 768px) {
      width: 120px;
      height: 120px;
    }
  }

  h2 {
    margin-bottom: 20px;
    font-size: 2rem;
    color: var(--sidebar-text-color);

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  p, a {
    font-size: 1rem;
    margin-bottom: 10px;
    color: var(--sidebar-text-color);

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }

  a {
    color: var(--link-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: var(--sidebar-text-color); 
    }
  }
`;

const MainContent = styled.div`
  background-color: var(--bg-color);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 20px;
  }

  h1 {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 40px;
    text-align: center;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100px;
      height: 4px;
      background-color: var(--primary-color);
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }

    @media (max-width: 768px) {
      font-size: 2rem;
      margin-bottom: 20px;
    }
  }

  h2 {
    font-size: 1.8rem;
    color: var(--primary-color);
    margin-bottom: 20px;
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 10px;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: 15px;
    }
  }

  h3 {
    font-size: 1.5rem;
    color: var(--primary-color);
    margin-top: 20px;

    @media (max-width: 768px) {
      font-size: 1.3rem;
      margin-top: 15px;
    }
  }

  p, li {
    font-size: 1rem;
    color: var(--cv-color);
    line-height: 1.6;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
    margin-bottom: 20px;

    li {
      background: none;
      color: var(--cv-color);
      padding: 8px 0;
      border-bottom: 1px solid var(--primary-color);
      margin-bottom: 5px;
      transition: background-color 0.3s ease, padding-left 0.3s ease;

      &:hover {
        background-color: var(--primary-color);
        color: var(--bg-color);
        padding-left: 10px;
      }

      @media (max-width: 768px) {
        padding: 5px 0;
      }
    }
  }

  section {
    margin-bottom: 40px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
`;

const CV = () => {
  const { t } = useTranslation(); // Use the useTranslation hook

  return (
    <CVContainer>
      <Content>
        <Sidebar
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <img src="assets/images/dolan2.jpg" alt={t('modalImageAlt')} loading="lazy" />
          <h2>Dylan Leijten</h2>
          <p>{t('frontendDeveloper')}</p>
          <p>{t('tilburgNetherlands')}</p>
          <p>Email: dylan@dyluxe-code.nl</p>
          <a href="https://www.linkedin.com/in/dylan-leijten-6b4655104/" target="_blank" rel="noopener noreferrer">
            {t('linkedinProfile')}
          </a>
        </Sidebar>

        <MainContent>
          <h1>{t('myResume')}</h1>

          <section>
            <h2>{t('profile')}</h2>
            <p>{t('over7YearsExperience')}</p>
          </section>

          <section>
            <h2>{t('skills')}</h2>
            <ul>
              <li>HTML5/(S)CSS3/Less</li>
              <li>JavaScript (ES6+)/TypeScript</li>
              <li>{t('accessibility')}</li>
              <li>VueJS 3, Vuetify 2</li>
              <li>Angular 15</li>
              <li>React 18</li>
              <li>CI/CD with Azure DevOps</li>
              <li>NPM 7, Webpack 5</li>
              <li>RESTful APIs</li>
              <li>GIT (version control)</li>
              <li>Scrum Agile Methodology</li>
            </ul>
          </section>

          <section>
            <h2>{t('relevantWorkExperience')}</h2>
            <h3>2023-2024 | {t('frontendDeveloper')} - Achmea - SAR</h3>
            <p>{t('achmeaProject')}</p>
            <p>{t('technologiesUsed')}: HTML, SCSS, Javascript, jQuery, NPM, Webpack, Azure DevOps, REST, CI/CD, .NET, C#</p>

            <h3>2019-2023 | {t('frontendDeveloper')} - Robeco</h3>
            <p>{t('robecoProject')}</p>
            <p>{t('technologiesUsed')}: HTML, SCSS, Less, Javascript, jQuery, Vue, Vuetify, NPM, Thymeleaf, Webpack, Azure DevOps, REST, CI/CD</p>

            <h3>2017-2019 | {t('frontendDeveloper')} - Copaco</h3>
            <p>{t('computariaProject')}</p>
            <p>{t('technologiesUsed')}: HTML, CSS, Angular 5, Angular Material</p>

            <h3>2014 - 2015 | {t('itAdministratorIntern')} - De Nassau</h3>
            <p>{t('itAdministratorInternDescription')}</p>

            <h3>2017-2019 | {t('headOfTechnicalService')} - Computaria</h3>
            <p>{t('computariaProject')}</p>
          </section>

          <section>
            <h2>{t('education')}</h2>
            <h3>2016 - 2021 | Avans {t('universityOfAppliedSciences')} - {t('informatics')}</h3>
            <p>{t('avansDescription')}</p>
          </section>

          <section>
            <h2>{t('training')}</h2>
            <ul>
              <li>2019 - {t('consultancySkills')} - Brout</li>
              <li>2019 - {t('presentationSkills')} - Brout</li>
              <li>2018 - {t('objectOrientationAndUML')}</li>
            </ul>
          </section>
        </MainContent>
      </Content>
    </CVContainer>
  );
};

export default CV;
