import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  /* Reset basis margin en padding */
  html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden; /* Voorkomt horizontaal scrollen */
  }

  /* Box-sizing inheritance voor alle elementen */
  *, *::before, *::after {
    box-sizing: inherit;
  }

  :root {
    --bg-color: #1c1c1c;
    --text-color: #f0e6d6; /* Gebruik deze kleur voor tekst in donkere modus */
    --primary-color: #e2b887;
    --secondary-color: #f4c789;
    --link-hover-color: #f4c789;
    --project-text-color: #ffffff;
    --footer-text-color: #f0e6d6;
    --filter-brightness: brightness(0.5);
    --background-color: #1c1c1c;
    --sidebar-text-color: #1c1c1c; 
    --link-color: #4b6cb7;
    --cv-color: #f0e6d6;
    --about-color: #f0e6d6;
  }

  [data-theme='light'] {
    --bg-color: #ffffff;
    --text-color: #ffffff; 
    --primary-color: #4b6cb7;
    --secondary-color: #333333;
    --link-hover-color: #4b6cb7;
    --project-text-color: #333333;
    --footer-text-color: #333333;
    --filter-brightness: brightness(0.5);
    --background-color: #f7f7f7;
    --sidebar-text-color: #ffffff;
    --link-color: #4b6cb7;
    --cv-color: #333333;
    --about-color:#333333;
  }

  body {
    font-family: 'Poppins', sans-serif;
    background-color: var(--bg-color);
    color: var(--text-color);
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  h1, h2, h3 {
    font-weight: 600;
    color: var(--primary-color);
  }

  a {
    text-decoration: none;
    color: var(--primary-color);
    transition: color 0.3s ease;

    &:hover {
      color: var(--link-hover-color);
    }
  }

  button, a {
    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-color);
      outline-offset: 4px;
    }
  }

  /* Hero specifieke stijlen */
  video {
    filter: brightness(0.5);
  }

  [data-theme='light'] video {
    filter: brightness(1); /* Verwijder donkere laag in light mode */
  }
`;

export default GlobalStyle;
