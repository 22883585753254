import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link as ScrollLink } from 'react-scroll';
import { useTranslation } from 'react-i18next';

const HeroContainer = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--text-color);
  padding: 0 20px;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
    filter: var(--filter-brightness);
  }
`;

const HeroContent = styled(motion.div)`
  max-width: 800px;
  z-index: 2;
  padding: 20px;

  h1 {
    font-size: 4rem;
    margin-bottom: 20px;
    color: var(--primary-color);
    font-weight: 700;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    background: linear-gradient(90deg, #e2b887, #e5c07b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 1.5s forwards;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 4px;
      background-color: var(--secondary-color);
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      animation: underline 1s forwards 1.5s;
    }

    @media (max-width: 768px) {
      font-size: 2.5rem;
    }
  }

  p {
    font-size: 1.5rem;
    margin-bottom: 30px;
    color: var(--text-color);
    line-height: 1.4;
    opacity: 0;
    animation: fadeIn 0.8s forwards 2s;

    @media (max-width: 768px) {
      font-size: 1.2rem;
      margin-bottom: 20px;
    }
  }

  .hero-subtext {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: var(--primary-color);
    line-height: 1.4;
    animation: fadeIn 1s forwards 2.5s;

    @media (max-width: 768px) {
      font-size: 1rem;
      margin-bottom: 20px;
    }
  }

  .hero-experience {
    font-size: 1rem;
    margin-bottom: 30px;
    color: var(--text-color);
    animation: fadeIn 1.2s forwards 2.8s;

    @media (max-width: 768px) {
      font-size: 0.9rem;
      margin-bottom: 20px;
    }
  }

  button {
    background-color: var(--primary-color);
    color: var(--bg-color);
    padding: 12px 24px;
    border: none;
    border-radius: 30px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    opacity: 0;
    animation: fadeIn 0.6s forwards 3s;

    &:hover {
      background-color: var(--secondary-color);
      color: var(--text-color);
    }

    &:focus-visible {
      outline: 3px solid var(--secondary-color);
      outline-offset: 4px;
    }

    @media (max-width: 768px) {
      font-size: 1rem;
      padding: 10px 20px;
    }
  }

  @keyframes shine {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes underline {
    from {
      opacity: 0;
      width: 0;
    }
    to {
      opacity: 1;
      width: 100%;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Hero = () => {
  const { t } = useTranslation(); // Initialize useTranslation hook

  return (
    <HeroContainer>
      <video autoPlay loop muted playsInline aria-hidden="true">
        <source src="/assets/video/typewriter.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <HeroContent>
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          {t('heroTitle')} 
        </motion.h1>
        <p>{t('heroDescription')}</p>
        <p className="hero-subtext">
          {t('heroSubtext')}
        </p>
        <p className="hero-experience">
          {t('heroExperience')}
        </p>
        <ScrollLink 
          to="projects" 
          smooth={true} 
          duration={800} 
          offset={-80} 
          tabIndex={0} 
          aria-label={t('viewMyWork')}
        >
          <button>{t('viewMyWork')}</button>
        </ScrollLink>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
